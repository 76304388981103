import React from "react"
import BasePage from "../components/base-page";
import Layout from "../components/layout";
import FAQ from "../components/faq";
import { Typography, Grid } from "@material-ui/core";
import { useIntl } from "../intl";

const FaqPage = (props) => {
  const intl = useIntl();
  const activeQ = props.pageContext.mdPageId;
  return (
    <BasePage {...props}>
      <Layout>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h3" component="h1">
              {intl.formatMessage({ id: "faq_title" })}
            </Typography>
          </Grid>
          <Grid item>
            {<FAQ active={activeQ} />}
          </Grid>
        </Grid>
      </Layout>
    </BasePage>
  );
}

export default FaqPage;
