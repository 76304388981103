import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "../intl"

const FAQSource = ({ children }) => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query FAQQuery {
      allFile(filter: { relativePath: { glob: "pages/faq/*/*.*.md" } }, sort: {fields: relativePath}) {
        nodes {
          id
          name
          childMarkdownRemark {
            fields {
              mdPageId
              mdPageSlug
              mdPageLocale
            }
            frontmatter {
              question
            }
            rawMarkdownBody
            html
          }
        }
      }
    }
  `);

  return children({
    ...data.allFile,
    nodes: data.allFile.nodes.filter(node => {
      return node.childMarkdownRemark.fields.mdPageLocale === intl.locale
    })
  })
}

export default FAQSource
