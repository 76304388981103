import React from "react"
import ReactMarkdown from "react-markdown"
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from "styled-components";
import { navigate } from "gatsby";
import { useIntl } from "../intl";
import FAQSource from "../datasources/faq"


const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  &.Mui-expanded {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  flex-direction: column;
`;

const FAQ = ({ active }) => {
  const intl = useIntl();

  const scrollTo = (isActive) => (node) => {
    setTimeout(() => {
      if(isActive && node) node.scrollIntoView();
    }, 0);
  };

  return (
    <div>
      <FAQSource>
        {({ nodes }) =>
          nodes.map(node => {
            const isActive = active === node.childMarkdownRemark.fields.mdPageId;
            return (
              <ExpansionPanel key={node.id} id={node.id} ref={scrollTo(isActive)} expanded={isActive} onChange={() => {
              !isActive ? navigate(intl.getPagePath(node.childMarkdownRemark.fields.mdPageId)) : navigate(intl.getPagePath("faq"));
            }}>
              <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                <b>{node.childMarkdownRemark.frontmatter.question}</b>
              </StyledExpansionPanelSummary>
              <StyledExpansionPanelDetails dangerouslySetInnerHTML={{ __html: node.childMarkdownRemark.html }}>
              </StyledExpansionPanelDetails>
            </ExpansionPanel>
            );
          })
        }
      </FAQSource>
    </div>
  )
}

export default FAQ
